import React from "react";

function MsgIcon() {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.75 9.75C14.75 10.1478 14.592 10.5294 14.3107 10.8107C14.0294 11.092 13.6478 11.25 13.25 11.25H4.25L1.25 14.25V2.25C1.25 1.85218 1.40804 1.47064 1.68934 1.18934C1.97064 0.908035 2.35218 0.75 2.75 0.75H13.25C13.6478 0.75 14.0294 0.908035 14.3107 1.18934C14.592 1.47064 14.75 1.85218 14.75 2.25V9.75Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default MsgIcon;
