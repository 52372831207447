/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Page from "../../components/page";
import Header from "../../components/header";
import ContactUs from "../../components/contact-us";
import Footer from "../../components/footer";
import classNames from "./thank.you.module.scss";
import ThankYouIcon from "../../icons/thank-you/thank-you-icon";
import MsgIcon from "../../icons/thank-you/msg-icon";
import GoHome from "../../components/go-home";
import "aos/dist/aos.css";
import { ANIMATION_TYPE } from "../../constants/animation";
import { _getFormatPrice } from "src/helpers/additionalFuctions";
import MapWithAddress from "src/components/small-map-with-address";
import AOS from "aos";
import { navigate } from "gatsby";

function ThankYouPage({ location }) {
  const [state, setState] = useState({});
  useEffect(() => {
    if (!location.state) {
      navigate("/");
    } else {
      setState(location.state);
    }
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Page>
      {location.state ? (
        <main>
          <div className={classNames.container}>
            <Header />
          </div>
          <GoHome />
          <div className={classNames.container} data-aos={ANIMATION_TYPE}>
            <h1 className={classNames.title}>Ďakujeme za objednávku!</h1>
          </div>
          <div className={classNames.container}>
            <div className={classNames.rectangle1} data-aos={ANIMATION_TYPE}>
              <div className={classNames.boxImg}>
                <ThankYouIcon />
              </div>
              <div className={classNames.spanContainer}>
                <span className={classNames.text1}>
                  Číslo vašej objednávky je:{" "}
                  <b className={classNames.numberColor}>23345</b>
                </span>
                <span className={classNames.text1}>
                  Vaša objednávka bude prepravená{" "}
                  <b className={classNames.timePeriod}>
                    {state ? state.timeOfDelivery : ""}.
                  </b>
                </span>
                <span className={classNames.text1}>
                  Pred vyzdvihnutím vás bude kontaktovať Doruč.to kuriér.
                </span>
              </div>
            </div>
            <div className={classNames.rectangle2} data-aos={ANIMATION_TYPE}>
              <div className={classNames.textRectangle2}>
                <h3 className={classNames.groupTitle}>Platba</h3>
                <p>
                  {state.basePriceOfDestination} € <br />
                </p>
                {location.state.additionalPrice.map((el, index) => {
                  return (
                    <div className={classNames.boxExtraPrice} key={index}>
                      <span dangerouslySetInnerHTML={el}></span>
                    </div>
                  );
                })}
                <h3 className={classNames.textPrice}>Spolu: {state.sum} €</h3>
                <p>
                  <b>Spôsob platby:</b> {state.methodPayment}
                </p>
              </div>
            </div>
            <div className={classNames.rectangle2} data-aos={ANIMATION_TYPE}>
              <div className={classNames.content}>
                <h3 className={classNames.groupTitle}>Adresa vyzdvihnutia</h3>
                <div className={classNames.address}>
                  <MapWithAddress
                    isNeededTochangeAddress
                    presetAddress={state.pickupAddress}
                  />
                </div>

                {state.customerAdditionalInfoToAddress ? (
                  <div className={classNames.rectangle3}>
                    <div className={classNames.msgIcon}>
                      <MsgIcon />
                    </div>
                    <span className={classNames.rectangle3Content}>
                      {state.customerAdditionalInfoToAddress}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={classNames.content}>
                <h3 className={classNames.groupTitle}>Objednávateľ</h3>
                <div className={classNames.addressText1}>
                  <b>{state.customerName}</b>
                  {state.companyName ? (
                    <>
                      <span>
                        {state.companyName}, {state.companyStreet},{" "}
                        {state.companyZipCode} {state.companyCountry}{" "}
                      </span>
                      <span>
                        IČO: {state.companyRegistrationCode},
                        {state.companyTaxNumber
                          ? `DIČ:${state.companyTaxNumber}`
                          : ""}{" "}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  <div className={classNames.info}>
                    <span>{state.customerEmail}</span>
                    <span>{state.customerTelephone}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.rectangle2} data-aos={ANIMATION_TYPE}>
              <div className={classNames.content}>
                <h3 className={classNames.groupTitle}>Adresa doručenia</h3>
                <div className={classNames.address}>
                  <MapWithAddress
                    isNeededTochangeAddress
                    presetAddress={state.deliveryAddress}
                  />
                </div>

                {state.addresseeAdditionalAddressInfo ? (
                  <div className={classNames.rectangle3}>
                    <div className={classNames.msgIcon}>
                      <MsgIcon />
                    </div>
                    <span className={classNames.rectangle3Content}>
                      {state.addresseeAdditionalAddressInfo}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={classNames.content}>
                <h3 className={classNames.groupTitle}>Adresát</h3>
                <div className={classNames.addressText1}>
                  <b>{state.addresseeFullName}</b>
                  <span>{state.addresseeEmail}</span>
                  <span>{state.addresseeTelephone}</span>
                </div>
              </div>
            </div>
          </div>
          <section data-aos={ANIMATION_TYPE}>
            <ContactUs />
          </section>
          <Footer />
        </main>
      ) : (
        ""
      )}
    </Page>
  );
}

export default ThankYouPage;
