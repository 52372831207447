import React from "react";

function ThankYouIcon() {
    return (
        <svg width="138" height="106" viewBox="0 0 138 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M132.198 60.4765H54.9811H49.7783C46.5781 60.4765 43.9767 57.8608 43.9767 54.689C43.9767 51.4966 46.5987 48.9014 49.7783 48.9014H74.6984C77.8986 48.9014 80.5 46.2857 80.5 43.1138C80.5 39.9214 77.8779 37.3263 74.6984 37.3263H58.491C55.2908 37.3263 52.6894 34.7106 52.6894 31.5387C52.6894 28.3463 55.3115 25.7512 58.491 25.7512H59.7298C62.93 25.7512 65.5314 23.1354 65.5314 19.9636C65.5314 16.7712 62.9093 14.176 59.7298 14.176H23.5987C20.3986 14.176 17.7971 16.7918 17.7971 19.9636C17.7971 23.156 20.4192 25.7512 23.5987 25.7512H25.4156C28.6158 25.7512 31.2172 28.3669 31.2172 31.5387C31.2172 34.7311 28.5952 37.3263 25.4156 37.3263H5.80162C2.62208 37.3057 0 39.9214 0 43.0933C0 46.2857 2.62208 48.9014 5.80162 48.9014H25.6221C28.8223 48.9014 31.4237 51.5171 31.4237 54.689C31.4237 57.8814 28.8016 60.4765 25.6221 60.4765H20.1095H17.5081C14.3079 60.4765 11.7065 63.0923 11.7065 66.2641C11.7065 69.4565 14.3285 72.0517 17.5081 72.0517H78.2289C81.4291 72.0517 84.0305 74.6674 84.0305 77.8392C84.0305 81.0317 81.4084 83.6268 78.2289 83.6268H78.0431C74.8429 83.6268 72.2415 86.2425 72.2415 89.4144C72.2415 92.6068 74.8635 95.2019 78.0431 95.2019H125.612C128.812 95.2019 131.414 92.5862 131.414 89.4144C131.414 86.2219 128.792 83.6268 125.612 83.6268H121.111C117.911 83.6268 115.31 81.0111 115.31 77.8392C115.31 74.6468 117.932 72.0517 121.111 72.0517H132.198C135.399 72.0517 138 69.4359 138 66.2641C138 63.0923 135.378 60.4765 132.198 60.4765Z" fill="url(#paint0_linear)"/>
            <path d="M31.981 22.0026H24.0734C22.9379 22.0026 22.0088 21.0757 22.0088 19.9429C22.0088 18.8101 22.9379 17.8833 24.0734 17.8833H31.981C33.1165 17.8833 34.0456 18.8101 34.0456 19.9429C34.0456 21.0757 33.1165 22.0026 31.981 22.0026Z" fill="#D2E8F8"/>
            <path d="M130.464 68.3442H121.958C120.822 68.3442 119.893 67.4174 119.893 66.2846C119.893 65.1518 120.822 64.225 121.958 64.225H130.464C131.6 64.225 132.529 65.1518 132.529 66.2846C132.529 67.4174 131.6 68.3442 130.464 68.3442Z" fill="#D2E8F8"/>
            <path d="M120.244 6.22577C117.127 5.36073 116.734 4.90761 115.929 1.36505C115.826 0.850137 115.372 0.5 114.856 0.5C114.339 0.5 113.885 0.850137 113.782 1.36505C112.997 4.90761 112.584 5.36073 109.467 6.22577C108.992 6.34935 108.662 6.80247 108.662 7.29678C108.662 7.79109 108.992 8.22362 109.467 8.36779C112.584 9.23284 112.977 9.68596 113.782 13.2285C113.885 13.7228 114.339 14.0936 114.856 14.0936C115.372 14.0936 115.826 13.7434 115.929 13.2285C116.734 9.68596 117.127 9.23284 120.244 8.36779C120.719 8.24421 121.049 7.79109 121.049 7.29678C121.049 6.80247 120.719 6.36995 120.244 6.22577Z" fill="#FFC92F"/>
            <path d="M37.3496 90.0322C35.223 89.4349 34.9546 89.126 34.3971 86.7162C34.3146 86.3661 34.0049 86.1189 33.6539 86.1189C33.3029 86.1189 32.9932 86.3661 32.9106 86.7162C32.3738 89.126 32.0848 89.4349 29.9788 90.0322C29.6485 90.1146 29.4214 90.4235 29.4214 90.7531C29.4214 91.0826 29.6485 91.3916 29.9788 91.4739C32.1054 92.0712 32.3738 92.3802 32.9106 94.7899C32.9932 95.1401 33.3029 95.3872 33.6539 95.3872C34.0049 95.3872 34.3146 95.1401 34.3971 94.7899C34.934 92.3802 35.223 92.0712 37.3496 91.4739C37.6799 91.3916 37.907 91.0826 37.907 90.7531C37.907 90.4235 37.6799 90.1352 37.3496 90.0322Z" fill="#FFC92F"/>
            <path d="M125.467 52.5675C126.608 52.5675 127.532 51.6454 127.532 50.5079C127.532 49.3704 126.608 48.4482 125.467 48.4482C124.327 48.4482 123.403 49.3704 123.403 50.5079C123.403 51.6454 124.327 52.5675 125.467 52.5675Z" fill="#FFC92F"/>
            <path d="M65.8205 105.5C66.9608 105.5 67.8851 104.578 67.8851 103.44C67.8851 102.303 66.9608 101.381 65.8205 101.381C64.6802 101.381 63.7559 102.303 63.7559 103.44C63.7559 104.578 64.6802 105.5 65.8205 105.5Z" fill="#FFC92F"/>
            <path d="M7.68084 30.3441C8.82111 30.3441 9.74548 29.422 9.74548 28.2845C9.74548 27.147 8.82111 26.2249 7.68084 26.2249C6.54058 26.2249 5.61621 27.147 5.61621 28.2845C5.61621 29.422 6.54058 30.3441 7.68084 30.3441Z" fill="#FFC92F"/>
            <path d="M89.6875 43.4431L70.4245 46.8415C69.4128 47.0269 68.4218 46.512 67.9882 45.5851C67.5546 44.6583 67.7817 43.5667 68.5663 42.9076C76.0816 36.4816 79.4263 29.9525 78.7862 22.9086C78.5591 20.4782 80.376 18.1714 82.8123 17.7801C85.3105 17.3888 87.8087 17.9655 89.8733 19.4278C91.9379 20.8901 93.3006 23.0528 93.7548 25.5449L93.8787 26.2864C94.9523 32.3417 93.8374 36.5228 91.4011 42.1249C91.0708 42.8046 90.4307 43.2989 89.6875 43.4431Z" fill="#FFBC91"/>
            <path d="M113.74 41.7955C113.059 37.9645 109.384 35.39 105.523 36.0697L69.6191 42.3927C69.2268 42.4545 68.8551 42.6399 68.5454 42.9076C66.295 44.8437 63.962 46.512 62.0831 47.8508C61.4225 48.3245 60.8237 48.757 60.2456 49.1689C59.5643 49.6838 59.2133 50.5283 59.3578 51.3727L64.4162 79.8162C64.5607 80.6607 65.1801 81.3404 66.0059 81.5875C75.7923 84.4916 83.3695 83.7295 92.1029 82.1848C93.4449 81.9377 94.8282 81.6905 96.2528 81.4227C97.8219 81.1344 99.453 80.8255 101.146 80.5371L108.186 79.3013C112.027 78.6217 114.607 74.9555 113.926 71.104C113.699 69.8064 113.121 68.6736 112.336 67.7262C114.132 66.1403 115.082 63.7099 114.628 61.1972C114.401 59.8996 113.823 58.7668 113.038 57.8194C114.834 56.2335 115.784 53.8031 115.33 51.2904C115.02 49.4779 114.029 47.9537 112.667 46.9239C113.678 45.3998 114.071 43.5873 113.74 41.7955Z" fill="#FFBC91"/>
            <path d="M105.689 23.2381L100.94 24.0826C99.7012 24.3091 98.545 23.4853 98.3179 22.2495C98.0907 21.0137 98.9166 19.8603 100.155 19.6338L104.904 18.7893C106.143 18.5628 107.299 19.3866 107.526 20.6224C107.733 21.8376 106.907 23.0116 105.689 23.2381Z" fill="#FFC92F"/>
            <path d="M72.4274 29.0874L67.6787 29.9318C66.4399 30.1584 65.2837 29.3345 65.0566 28.0987C64.8295 26.863 65.6554 25.7096 66.8941 25.483L71.6428 24.6386C72.8816 24.412 74.0378 25.2359 74.2649 26.4716C74.492 27.7074 73.6455 28.8608 72.4274 29.0874Z" fill="#FFC92F"/>
            <path d="M77.3617 15.5144C76.5978 15.6586 75.772 15.3908 75.2352 14.7523L72.1382 11.0656C71.333 10.1182 71.4569 8.69702 72.4273 7.89377C73.377 7.09051 74.8016 7.21409 75.6068 8.18212L78.7038 11.8689C79.509 12.8163 79.3851 14.2374 78.4147 15.0407C78.1257 15.2878 77.754 15.4526 77.3617 15.5144Z" fill="#FFC92F"/>
            <path d="M91.6286 13.0018C91.2569 13.0636 90.844 13.043 90.4724 12.8988C89.2955 12.4663 88.6968 11.1687 89.1304 10.0153L90.7821 5.48416C91.2156 4.31017 92.5163 3.71288 93.6725 4.1454C94.8494 4.57792 95.4481 5.87549 95.0145 7.02888L93.3628 11.5601C93.0738 12.3427 92.3925 12.8782 91.6286 13.0018Z" fill="#FFC92F"/>
            <path d="M58.5114 75.9854L60.576 87.6841L56.1164 88.4667L54.0517 76.768C53.8246 75.5322 54.6505 74.3789 55.8893 74.1523C57.1074 73.9463 58.2842 74.7702 58.5114 75.9854Z" fill="#73C3FF"/>
            <path d="M105.689 23.2381L100.94 24.0826C99.7012 24.3091 98.545 23.4853 98.3179 22.2495C98.0907 21.0137 98.9166 19.8603 100.155 19.6338L104.904 18.7893C106.143 18.5628 107.299 19.3866 107.526 20.6224C107.733 21.8376 106.907 23.0116 105.689 23.2381Z" fill="white"/>
            <path d="M72.4274 29.0874L67.6787 29.9318C66.4399 30.1584 65.2837 29.3345 65.0566 28.0987C64.8295 26.863 65.6554 25.7096 66.8941 25.483L71.6428 24.6386C72.8816 24.412 74.0378 25.2359 74.2649 26.4716C74.492 27.7074 73.6455 28.8608 72.4274 29.0874Z" fill="white"/>
            <path d="M77.3617 15.5144C76.5978 15.6586 75.772 15.3908 75.2352 14.7523L72.1382 11.0656C71.333 10.1182 71.4569 8.69702 72.4273 7.89377C73.377 7.09051 74.8016 7.21409 75.6068 8.18212L78.7038 11.8689C79.509 12.8163 79.3851 14.2374 78.4147 15.0407C78.1257 15.2878 77.754 15.4526 77.3617 15.5144Z" fill="white"/>
            <path d="M91.6286 13.0018C91.2569 13.0636 90.844 13.043 90.4724 12.8988C89.2955 12.4663 88.6968 11.1687 89.1304 10.0153L90.7821 5.48416C91.2156 4.31017 92.5163 3.71288 93.6725 4.1454C94.8494 4.57792 95.4481 5.87549 95.0145 7.02888L93.3628 11.5601C93.0738 12.3427 92.3925 12.8782 91.6286 13.0018Z" fill="white"/>
            <path d="M58.5114 75.9854L60.576 87.6841L56.1164 88.4667L54.0517 76.768C53.8246 75.5322 54.6505 74.3789 55.8893 74.1523C57.1074 73.9463 58.2842 74.7702 58.5114 75.9854Z" fill="#73C3FF"/>
            <path d="M60.3489 44.0198L41.3336 47.3564C40.0949 47.583 39.2896 48.7364 39.4961 49.9722L46.2062 87.8899C46.4333 89.1257 47.5895 89.929 48.8282 89.7436L63.0949 87.2309C65.8202 86.716 67.9054 84.7387 68.6487 82.2466C68.9584 81.2373 69.041 80.1251 68.8345 79.0129L62.971 45.8323C62.7645 44.6171 61.5877 43.7933 60.3489 44.0198Z" fill="#427FEC"/>
            <path d="M58.4703 75.8411L60.5556 87.6839L56.096 88.4666L54.0107 76.6237C53.7836 75.388 54.6095 74.2346 55.8482 74.008C57.087 73.802 58.2639 74.6053 58.4703 75.8411Z" fill="#3B5E8D"/>
            <defs>
                <linearGradient id="paint0_linear" x1="0" y1="54.6897" x2="137.995" y2="54.6897" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BADCF5"/>
                    <stop offset="1" stopColor="#81C9F5"/>
                </linearGradient>
            </defs>
        </svg>

    )
}

export default ThankYouIcon;
